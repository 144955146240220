import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './formControls.module.scss';

const TextArea = ({
  label,
  name,
  onChange,
  placeholder,
  required,
  theme,
  isValid,
  value,
  rows,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(undefined === value ? '' : value);

  useEffect(() => {
    onChange(textAreaValue);
  }, [textAreaValue]);

  return (
    <div
      className={classNames(styles.inputGroup, {
        [styles.default]: undefined === theme || 'default' === theme,
        [styles.purpleInverted]: 'purpleInverted' === theme,
        [styles.purple]: 'purple' === theme,
      })}
    >
      {label && (
        <label
          className={classNames(styles.label, {
            [styles.invalidLabel]: !isValid,
          })}
          htmlFor={name}
        >
          {label}
          {required && <React.Fragment> *</React.Fragment>}
        </label>
      )}
      <textarea
        className={classNames(styles.input, {
          [styles.invalidInput]: !isValid,
        })}
        rows={rows ?? 4}
        id={name}
        placeholder={placeholder}
        onChange={(e) => setTextAreaValue(e.target.value)}
        value={textAreaValue}
      />
    </div>
  );
};

export default TextArea;
