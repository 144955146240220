import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './formControls.module.scss';

const Select = ({ label, name, onChange, required, theme, isValid, options }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div
      className={classNames(styles.selectGroup, {
        [styles.default]: undefined === theme || 'default' === theme,
        [styles.purpleInverted]: 'purpleInverted' === theme,
        [styles.purple]: 'purple' === theme,
      })}
    >
      {label && (
        <label
          className={classNames(styles.label, {
            [styles.invalidLabel]: !isValid,
          })}
          htmlFor={name}
        >
          {label}
          {required && <React.Fragment> *</React.Fragment>}
        </label>
      )}
      <select
        className={classNames(styles.select, {
          [styles.invalidSelect]: !isValid,
        })}
        id={name}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
